import React from 'react';
import axios from 'axios';

function Contact() {
    function sendEmail(e) {
        e.preventDefault();

        let name = e.target.elements.name.value;
        let email = e.target.elements.email.value;
        let phone = e.target.elements.phone.value;
        let message = e.target.elements.message.value;

        axios({
            method: 'post',
            url: `https://capmex.mx/mail/index.php`,
            data: {
                name,
                email,
                phone,
                message
            }
        }).then((response) => {
            if (response.data.status)
                alert("Mensaje enviado correctamente");
            else
                alert("Error al enviar mensaje, por favor intenta mas tarde");
        });
    }

  return (
    <section className="page-section" id="contact">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Contacto</h2>
                <h3 className="section-heading text-uppercase">¡Queremos trabajar contigo!</h3>                
            </div>
            <h4 className="section-subheading text-muted"><span style={{color: '#FFF'}}>Oficinas físicas</span></h4>
            <h5 className="section-subheading text-muted" style={{marginBottom: "20px"}}><span style={{color: '#FFF'}}>GUADALAJARA, CIUDAD DE MÉXICO, MÉRIDA, CANCÚN, CHETUMAL Y VILLAHERMOSA</span></h5>
            <h4 className="section-subheading text-muted"><span style={{color: '#FFF'}}>Escríbenos</span></h4>
            <h5 className="section-subheading text-muted" style={{marginBottom: "20px", color: '#FFF'}}><span style={{color: '#FFF'}}>contacto@capmex.mx</span></h5>
            <form id="contactForm" onSubmit={sendEmail}>
                <div className="row align-items-stretch mb-5">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input className="form-control" type="text" name="name" placeholder="Nombre *" />
                        </div>
                        <div className="form-group">
                            <input className="form-control" type="email" name="email" placeholder="Correo *" />
                        </div>
                        <div className="form-group mb-md-0">
                            <input className="form-control" id="phone" name="phone" placeholder="Telefono *" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-textarea mb-md-0">
                            <textarea className="form-control" id="message" name="message" placeholder="Mensaje *"></textarea>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <input className="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit" value="Enviar mensaje" />
                </div>
            </form>
        </div>
    </section>
  );
}

export default Contact;