import React from 'react';
import Navbar from '../Navbar';
import Masthead from '../Masthead';
import Services from '../Services';
import Portafolio from '../Portfolio';
import Contact from '../Contact';
import Footer from '../Footer';
import About from '../About';

function Home() {
  return (
    <div id="page-top">
      <Navbar/>
      <Masthead/>
      <Services/>
      <Portafolio/>
      <About/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Home;