import React from 'react';

function Services() {
  return (
    <>
        <section className="page-section custom-background" id="services">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Somos CAPMEX</h2>
                    <h3 className="section-subheading text-muted">¿Quiénes somos?</h3>
                </div>
                <div className="row text-center">
                    <div className="offset-md-2 col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-search fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3 about-text">Experiencia que trasciende</h4>
                        <p className="text-muted about-text">Somos una firma creada bajo la dirección de consultores especializados con más de 10 años de experiencia en el sector empresarial y corporativo.</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-lightbulb fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3 about-text">Asesoría personalizada</h4>
                        <p className="text-muted about-text">Nuestra misión es crear relaciones de valor a través de la asesoría especializada y consolidarnos como una firma de asesoría empresarial que brinde soluciones contables, jurídicas, financieras y patrimoniales, a través de una interacción corporativa constante.</p>
                    </div>
                    
                </div>
                <div className="row text-center">
                    <div className="offset-md-2 col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-toolbox fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3 about-text">Nuestro futuro juntos</h4>
                        <p className="text-muted about-text">Nuestra visión es posicionarnos como la firma de asesores empresariales más importante de México, estableciendo vínculos perdurables con nuestros clientes, dentro del marco de legalidad.</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-award fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3 about-text">Valores</h4>
                        <p className="text-muted about-text">Ofrecemos un trabajo y comunicación legal, honesto, continuo y profesional con cada uno de nuestros clientes y personas de nuestra red.</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Services;