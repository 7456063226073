import React from 'react';

function Masthead() {
  return (
    <>
        <header className="masthead">
            <div className="container">
                <div className="masthead-heading text-uppercase" style={{marginTop: "15%"}}>Interacción Corporativa</div>
                <a className="btn btn-primary btn-xl text-uppercase" href="#contact">¡Contáctanos!</a>
            </div>
        </header>
    </>
  );
}

export default Masthead;