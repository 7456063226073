import React from 'react';

function About() {
  return (
    <section className="page-section" id="about">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Creciendo junto a nuestros clientes</h2>
                <h3 className="section-subheading text-muted">Casos de Éxito.</h3>
            </div>
            <ul className="timeline">
                <li style={{display: 'flex'}}>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                    <div className="timeline-panel"  style={{display: 'flex'}}>
                        <div className="timeline-body" style={{margin: 'auto'}}>
                            <p className="text-muted about-text" >
                                <b>
                                    ¨Gracias a su asesoría y profesionalismo pudimos realizar la Inscripción de mi empresa en el padrón de Registro de Prestadoras de Servicios Especializados u Obras Especializadas, cumpliendo con la normatividad vigente¨
                                </b>
                            </p>
                            <p className="text-muted about-text" >
                                Abelardo E. - Director General de una empresa de construcción especializada.
                            </p>
                        </div>
                    </div>
                </li>
                <li className="timeline-inverted" style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                    <div className="timeline-panel" style={{display: 'flex'}}>
                        <div className="timeline-body" style={{margin: 'auto'}}>
                            <p className="text-muted about-text" >
                                <b>
                                    ¨Estoy muy agradecido con CAPMEX al haber obtenido una sentencia favorable para mi empresa, en la que se declaró la nulidad de un crédito fiscal determinado por parte del SAT¨
                                </b>
                            </p>
                            <p className="text-muted about-text" >
                                Marco B. - Gerente Administrativo de una empresa en el ramo de servicios.
                            </p>
                        </div>
                    </div>
                </li>
                <li style={{display: 'flex'}}>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                    <div className="timeline-panel" style={{display: 'flex'}}>
                        <div className="timeline-body" style={{margin: 'auto'}}>
                            <p className="text-muted about-text">
                                <b>
                                    ¨CAPMEX nos brindó asesoría jurídica y nos ayudó a obtener una Resolución favorable en Juicio de Nulidad contra el procedimiento previsto en el artículo 69-B del Código Fiscal de la Federación¨
                                </b>
                            </p>
                            <p className="text-muted about-text">
                                José Julián A. - Gerente General de una empresa en el ramo de logística.
                            </p>
                        </div>
                    </div>
                </li>
                <li className="timeline-inverted" style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                    <div className="timeline-panel" style={{display: 'flex'}}>
                        <div className="timeline-body" style={{margin: 'auto'}}>
                            <p className="text-muted about-text">
                                <b>
                                    ¨Con motivo de la implementación de un acuerdo conclusivo y la asesoría de CAPMEX, mi empresa logro firmar un acuerdo conclusivo con el SAT ante la PRODECON, resolviéndose de esta forma la situación fiscal de la empresa de manera favorable¨
                                </b>
                            </p>
                            <p className="text-muted about-text">
                                Mario M. - Dueño de una empresa de transporte.
                            </p>
                        </div>
                    </div>
                </li>
                <li style={{display: 'flex'}}>
                    <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/5.jpg" alt="..." /></div>
                    <div className="timeline-panel" style={{display: 'flex'}}>
                        <div className="timeline-body" style={{margin: 'auto'}}>
                            <p className="text-muted about-text">
                                <b>
                                    ¨CAPMEX me ayudo a tener un acercamiento con la autoridad fiscal y se logró tramitar y llegar a un acuerdo para el pago en parcialidades de impuestos federales¨
                                </b>
                            </p>
                            <p className="text-muted about-text">
                                Guadalupe M. -  Gerente Administrativo en una empresa de producción de alimentos.
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
  );
}

export default About;