import React from 'react';
import Home from './components/pages/Home';
import './App.css';

function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
