import React from 'react';
import asesoria_contable from '../assets/img/portfolio/asesoria_contable.png'
import capital_humano from '../assets/img/portfolio/capital_humano.png'
import asesoseria_juridica from '../assets/img/portfolio/asesoria_juridica.png'
import consultroria_corporativa from '../assets/img/portfolio/consultoria_corporativa.png'
import cursos from '../assets/img/portfolio/cursos.png'
import registro_marca from '../assets/img/portfolio/registro_marca.png'

function Portafolio() {
    const services = [
        {
            key: "1",
            name: "Asesoría y Consultoría Contable",
            img: asesoria_contable,
            description: [
                "Servicios de asesoría y consultoría en materia contable.",
                "Servicios de asesoría y consultoría para el cumplimiento de obligaciones fiscales en materia federal y estatal.",
                "Servicios de asesoría y consultoría en materia de auditoría contable-financiera.",
                "Servicios de asesoría y consultoría en materia de control de inventarios.",
                "Servicios de asesoría y consultoría en material de seguridad social.",
                "Servicios de asesoría y consultoría en material de Normas de Información Financiera.",
                "Servicios de asesoría y consultoría para la realización de trámites ante el Servicio de Administración Tributaria, Instituto Mexicano del Seguro Social e Instituto del Fondo Nacional de la Vivienda para los Trabajadores.",
                "Servicios de asesoría y consultoría de reingeniería fiscal."
            ]
        },
        {
            key: "2",
            name: "Asesoría y Consultoría en Material de Capital Humano",
            img: capital_humano,
            description: [
                "Asesoría y consultoría en materia de capital humano dentro del marco de legalidad previsto para el padrón de Registro de Prestadoras de Servicios Especializados u Obras Especializadas.",
                "Asesoría y consultoría para la inscripción en el padrón de Registro de Prestadoras de Servicios Especializados u Obras Especializadas."
            ]
        },
        {
            key: "3",
            name: "Asesoría y Consultoría Jurídica",
            img: asesoseria_juridica,
            description: [
                "Servicios de asesoría y consultoría jurídica en procedimientos administrativos.",
                "Servicios de asesoría y consultoría jurídica en facultades de comprobación de autoridades fiscales y estatales.",
                "Servicios de asesoría y consultoría jurídica para la realización de trámites ante el Servicio de Administración Tributaria, Instituto Mexicano del Seguro Social e Instituto del Fondo Nacional de la Vivienda para los Trabajadores.",
                "Servicios de asesoría y consultoría jurídica para la obtención de diversos permisos.",
                "Servicios de asesoría y consultoría jurídica para la interposición de medios de defensa en materia fiscal.",
                "Servicios de asesoría y consultoría jurídica para la interposición de juicios mercantiles.",
                "Servicios de asesoría y consultoría jurídica de amparo contra leyes.",
                "Servicios de asesoría y consultoría jurídica por cancelación de sellos digitales.",
                "Servicios de asesoría y consultoría jurídica dentro del procedimiento previsto en el artículo 69-B del Código Fiscal de la Federación.",
                "Servicios de asesoría y consultoría para la tramitación y gestión de quejas y acuerdos conclusivos ante la Procuraduría de la Defensa del Contribuyente.",
                "Servicios de asesoría y consultoría para la interposición de recursos de revocación, recursos de inconformidad y consultas especializadas en material fiscal."
            ]
        },
        {
            key: "4",
            name: "Asesoría y Consultoría Corporativa",
            img: consultroria_corporativa,
            description: [
                "Servicios de asesoría y consultoría en materia contable.",
                "Servicios de asesoría y consultoría para la constitución de personas morales.",
                "Servicios de asesoría y consultoría para la fusión y escisión de sociedades mercantiles.",
                "Servicios de asesoría y consultoría para la liquidación de personas morales.",
                "Servicios de asesoría y consultoría de auditoría de jurídica y libros corporativos.",
                "Servicios de asesoría y consultoría para la revisión de contratos mercantiles y civiles.",
                "Servicios de asesoría y consultoría para la creación de fideicomisos.",
                "Servicios de asesoría y consultoría en materia de prevención de lavado de dinero.",
                "Servicios de asesoría y consultoría de reingeniería corporativa."
            ]
        },
        {
            key: "5",
            name: "Cursos y Formación Corporativa y Empresarial",
            img: cursos,
            description: [
                "Capacitaciones e impartición de cursos en materia jurídica, contable y fiscal."
            ]
        },
        {
            key: "6",
            name: "Servicios de Asesoría en Materia de Propiedad Intelectual",
            img: registro_marca,
            description: [
                "Servicios de valuación de intangibles y obras.",
                "Servicios de asesoría de derechos de autor.",
                "Servicios de registros de marcas y reserva de derechos.",
                "Gestión de trámites ante el Instituto Mexicano de Propiedad Intelectual."

            ]
        }
    ]

  return (
    <>
        <section className="page-section bg-light" id="portfolio" key="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Soluciones que se adaptan a tu empresa</h2>
                    <h3 className="section-subheading text-muted">Nuestros Servicios</h3>
                </div>
                <div className="row">
                    {
                        services.map(service => {
                            return (
                                <div className="col-lg-4 col-sm-6 mb-4" >
                                    <div className="portfolio-item">
                                        <a className="portfolio-link" data-bs-toggle="modal" href={"#portfolioModal" + service.key}>
                                            <div className="portfolio-hover">
                                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                            </div>
                                            <img className="img-fluid" src={service.img} alt={service.name} style={{borderTopRightRadius: "10px", borderTopLeftRadius: "10px", width: "100%", height: "224px"}}/>
                                        </a>
                                        <div className="portfolio-caption" style={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                                            <div className="portfolio-caption-heading">{service.name}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
        </section>
        {
            services.map(service => {
                return (
                    <div className="portfolio-modal modal fade" id={"portfolioModal" + service.key} tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" style={{height: '20px', width: '20px'}} alt="Close modal" /></div>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10">
                                            <div className="modal-body">
                                                <h4 className="text-uppercase">{service.name}</h4>
                                                <ul className='about-text'>
                                                    {
                                                        service.description.map(des => {
                                                            return (
                                                                <li className="text-muted" style={{marginBottom: '0px', textAlign: 'left'}}>
                                                                    {des}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button" style={{marginTop: '40px'}}>
                                                    <i className="fas fa-times me-1"></i>
                                                    Cerrar servicio
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            )
        }
    </>
  );
}

export default Portafolio;